import React from "react";
import aa from "../../components/images/aa.webp";
import amh from "../../components/images/amh.webp";
import anishque from "../../components/images/anishque.webp";
import cios from "../../components/images/cios.webp";
import fignter from "../../components/images/fignter.webp";
import hcabaj from "../../components/images/hcabaj.webp";
import nextgen from "../../components/images/nextgen.webp";
import bala from "../../components/images/bala.webp";
import chandra from "../../components/images/chandra.webp";
import studyverse from "../../components/images/studyverse.webp";
import vinasia from "../../components/images/vinasia.webp";
import den from "../../components/images/den.webp";
import devjilogo from "../../components/images/devjilogo.webp";
import flip from "../../components/images/flip.webp";
import incredible from "../../components/images/incredible.webp";
import jj from "../../components/images/jj.webp";
import petayu from "../../components/images/petayu.webp";
import princeviraj from "../../components/images/princeviraj.webp";
import se from "../../components/images/se.webp";
import shadivivah from "../../components/images/shadivivah.webp";
import subhmed from "../../components/images/subhmed.webp";
import fornear from "../../components/images/fornear.webp";
import sanjulogo from "../../components/images/sanjulogo.webp";
import gala from "../../components/images/gala.webp";
import swa from "../../components/images/swa.webp";
import chathanya from "../../components/images/chathanya.webp";
import kaya from "../../components/images/jsss150.webp";
import ks from "../../components/images/ks.webp";
import hdr from "../../components/images/woj150.webp";
import bharma from "../../components/images/bharma.webp";

import { styled } from "styled-components";
function Carousel() {
  return (
    <Wrapper>
      <div className="container-fluid pt-5">
        <h2 className="hp text-center text-light mb-4">Our Happy Clients</h2>
        <div
          className="underline mx-auto"
          style={{
            height: 3,
            width: "10rem",
            backgroundColor: "#86ff68",
            marginTop: 10,
            marginBottom: 30,
          }}
        ></div>

        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
          data-interval="3000"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 0"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="5"
              aria-label="Slide 5"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="card-group">
                <div className="card  border-0">
                  <img src={aa} className="w-75 shadow-lg" alt="..." />
                </div>
                <div className="card  border-0">
                  <img
                    src={amh}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={anishque}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={cios}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={fignter}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="card-group">
                <div className="card border-0">
                  <img
                    src={bala}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={nextgen}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={chandra}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={studyverse}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={vinasia}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="card-group">
                <div className="card  border-0">
                  <img
                    src={den}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={devjilogo}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={flip}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={incredible}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={jj}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="card-group">
                <div className="card  border-0">
                  <img
                    src={petayu}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={princeviraj}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={se}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={shadivivah}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={subhmed}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="card-group">
                <div className="card  border-0">
                  <img
                    src={gala}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={fornear}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={sanjulogo}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={swa}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={hcabaj}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="card-group">
                <div className="card  border-0">
                  <img
                    src={chathanya}
                    className="w-75 shadow-lg"
                    alt="..."
                    
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={kaya}
                    className="w-75 shadow-lg"
                    alt="..."
                  />
                </div>
                <div className="card  border-0">
                  <img src={ks} className="w-75 shadow-lg" alt="..."  />
                </div>
                <div className="card  border-0">
                  <img
                    src={bharma}
                    className="w-75 shadow-lg"
                    alt="..." 
                  />
                </div>
                <div className="card  border-0">
                  <img
                    src={hdr}
                    className="w-75 shadow-lg"
                    alt="..." 
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon text-dark"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>

            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

export default Carousel;
const Wrapper = styled.div`
background: #002c63;
  .card {
    background: #002c63;
  }
  img {
    border-radius: 2rem;
    background:  #0d376b;
    box-shadow: 0px 10px 15px 19px rgba(13,55,0,0.1);
  }
  /* .card:hover img {
    transition: transform 0.3s ease-in-out;
  } */
  .carousel-inner {
    height: 20rem;
    padding: 1rem;
    @media screen and (max-width: 768px) {
      margin-left: 2.5rem;
      height: 86rem;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-left: 0rem;
      height: 15rem;
    }
  }
  h2 {
    font-size: 34px;
    color: #1e1666;
    @media screen and (max-width: 768px) {
      font-size: 1.4rem;
   
    }
  }

  .card {
    padding: 10px;
    width: clamp(250px,30%,250px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.card img{
    background-color: rgb(255, 255, 255) !important;
    padding: 15px !important;
    cursor: pointer;
    transition: all 0.5s;
    min-height: 150px;
}
.card img:hover{
    transform: scale(.9);
}
`;
